// assets
import { IconSeeding, IconJumpRope, IconTool } from '@tabler/icons';

// constant

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const adminProductProfilingMenuItems = {
    id: 'product-profiling-root',
    title: 'Value Chain Profiling',
    type: 'group',
    systemModule: 'PRODUCT',
    children: [
        {
            id: 'product-profiling',
            title: 'Product Profiling',
            type: 'collapse',
            icon: IconSeeding,
            systemSubModule: 'PRODUCT_RECORDS',
            children: [
                {
                    id: 'product-management',
                    title: 'Product management',
                    type: 'item',
                    url: '/app/users/product-management',
                    breadcrumbs: false,
                    feature: 'PRODUCTS_PROFILING'
                }
                // {
                //     id: 'manage-value-chains',
                //     title: 'Manage Value Chains',
                //     type: 'item',
                //     url: '/app/users/manage-value-chains',
                //     breadcrumbs: false,
                //     feature: 'PRODUCTS_PROFILING'
                // }
            ]
        },
        {
            id: 'farm-inputs',
            title: 'Farm Inputs',
            type: 'collapse',
            icon: IconJumpRope,
            systemSubModule: 'INPUTS_RECORDS',
            children: [
                {
                    id: 'input-management',
                    title: 'Input Management',
                    type: 'item',
                    url: '/app/users/farm-inputs',
                    breadcrumbs: false,
                    feature: 'FARM_INPUTS_PROFILING'
                }
            ]
        },
        {
            id: 'farm-equipment',
            title: 'Farm Equipment',
            type: 'collapse',
            icon: IconTool,
            systemSubModule: 'EQUIPMENT_RECORDS',
            children: [
                {
                    id: 'farm-equipment',
                    title: 'Farm Equipment',
                    type: 'item',
                    url: '/app/users/farm-equipment',
                    breadcrumbs: false,
                    feature: 'FARM_EQUIPMENT_PROFILING'
                }
            ]
        }
    ]
};

export default adminProductProfilingMenuItems;
