import jwtDecode from 'jwt-decode';
import { currentUserUtils } from 'utils/currentUserUtils';
import { Navigate } from 'react-router-dom';
import secureLocalStorage from 'react-secure-storage';

export const isValidAccessToken = () => {
    try {
        let token = secureLocalStorage.getItem('accessToken');
        token = jwtDecode(token);
        return !!token && Date.now() <= token.exp * 1000;
    } catch (e) {
        console.log(e);
        return false;
    }
};

export const routeSelector = () => {
    console.log('2dance2 ---');
    switch (currentUserUtils()?.userType) {
        case 'STAFF':
            <Navigate to="/app/staff/dashboard" replace={true} />;

            // navigate(0);
            break;
        case 'ADMINISTRATOR':
            console.log('dance ---');
            <Navigate to="/app/admin/dashboard" replace={true} />;

            // navigate('/admin/dashboard', { replace: true });
            // navigate(0);
            // window.location.reload(false);
            break;
        case 'FARMER':
            <Navigate to="/app/farmer/dashboard" replace={true} />;
            // navigate(0);
            break;
        // case 'SUPPLIER':
        //     navigate('/admin/dashboard', { replace: true });
        //     break;
        // case 'PARTNER':
        //     navigate('/admin/dashboard', { replace: true });
        //     break;
        // case 'APPLICANT_FARMER':
        //     navigate('/admin/dashboard', { replace: true });
        //     break;
        // case 'APPLICANT_SUPPLIER':
        //     navigate('/admin/dashboard', { replace: true });
        //     break;

        default:
            <Navigate to="/app/login" replace={true} />;
            break;
    }
};
