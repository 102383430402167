// assets
import { IconGrowth } from '@tabler/icons';

// constant

const associationProductionPlanMenuItems = {
    id: 'association-production-plan',
    title: 'Production Plan',
    type: 'group',
    systemModule: 'NONE',
    children: [
        {
            id: 'association-production-plan',
            title: 'Production Management',
            type: 'collapse',
            icon: IconGrowth,
            systemSubModule: 'NONE',
            children: [
                {
                    id: 'association-production-plan',
                    title: 'Production Plan',
                    type: 'item',
                    url: '/app/association/association-production-plan',
                    breadcrumbs: false,
                    feature: 'NONE'
                },
                {
                    id: 'association-production-tracker',
                    title: 'Production Plan Tracker',
                    type: 'item',
                    url: '/app/association/association-production-tracker',
                    breadcrumbs: false,
                    feature: 'NONE'
                }
                // {
                //     id: 'association-production-analysis',
                //     title: 'Production Analysis',
                //     type: 'item',
                //     url: '/app/association/association-production-analysis',
                //     breadcrumbs: false,
                //     feature: 'NONE'
                // }
            ]
        }
    ]
};

export default associationProductionPlanMenuItems;
