import { lazy } from 'react';

// project imports
import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

// login option 3 routing
const AuthLogin3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Login3')));
const AuthRegister3 = Loadable(lazy(() => import('views/pages/authentication/authentication3/Register3')));
const RegistrationPage = Loadable(lazy(() => import('views/registration/RegistrationPage')));
const FarmProduceCataloguePage = Loadable(lazy(() => import('views/pages/farm-produce-catalogue/FarmProduceCataloguePage')));

// ==============================|| AUTHENTICATION ROUTING ||============================== //

const AuthenticationRoutes = {
    path: '/',
    element: <MinimalLayout />,
    children: [
        {
            path: '/app/pages/register/register3',
            element: <AuthRegister3 />
        },
        {
            path: '/app/login',
            // path: '/pagesapp/loginapp/login3',
            element: <AuthLogin3 />
        },
        {
            path: '/app/register',
            // path: '/pagesapp/loginapp/login3',
            element: <RegistrationPage />
        },
        {
            path: '/app/home',
            // path: '/pagesapp/loginapp/login3',
            element: <FarmProduceCataloguePage />
        }
        // {
        //     path: '/',
        //     // path: '/pagesapp/loginapp/login3',
        //     element: <FarmProduceCataloguePage />
        // }
    ]
};

export default AuthenticationRoutes;
