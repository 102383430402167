// assets
import { IconFileReport } from '@tabler/icons';

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const farmerContractsMenuItems = {
    id: 'contracts-central',
    title: 'Contracts Central',
    type: 'group',
    systemModule: 'NONE',
    children: [
        {
            id: 'contracts-central',
            title: 'Contracts Central',
            type: 'collapse',
            icon: IconFileReport,
            systemSubModule: 'NONE',
            children: [
                {
                    id: 'my-contracts',
                    title: 'My Contracts',
                    type: 'item',
                    url: '/app/farmer/contracts/my-contracts',
                    breadcrumbs: false,
                    feature: 'NONE'
                },
                {
                    id: 'contract-opportunities',
                    title: 'Contract Opportunities',
                    type: 'item',
                    url: '/app/farmer/contract/opportunities',
                    breadcrumbs: false,
                    feature: 'NONE'
                }
            ]
        }
    ]
};

export default farmerContractsMenuItems;
