// assets
import {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconFriends,
    IconLockAccess,
    IconBriefcase,
    IconChartInfographic,
    IconReportSearch,
    IconUsers
} from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconLockAccess,
    IconFriends,
    IconChartInfographic,
    IconReportSearch,
    IconUsers
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const reportsMenuItems = {
    id: 'management-report',
    title: 'Reports',
    type: 'group',
    systemModule: 'SYSTEM_REPORTS',
    children: [
        {
            id: 'profiling-report',
            title: 'Farmer Group/Farmer Profiling Reports',
            type: 'collapse',
            icon: IconChartInfographic,
            systemSubModule: 'RECORDS',
            children: [
                {
                    id: 'equipments-report',
                    title: 'Equipment Report',
                    type: 'item',
                    url: '/app/users/equipments-report',
                    breadcrumbs: false,
                    feature: 'FARMING_REPORTS'
                },
                {
                    id: 'inputs-report',
                    title: 'Inputs Report',
                    type: 'item',
                    url: '/app/users/inputs-report',
                    breadcrumbs: false,
                    feature: 'FARMING_REPORTS'
                },
                {
                    id: 'farm-products-report',
                    title: 'Products Report',
                    type: 'item',
                    url: '/app/users/farm-products-report',
                    breadcrumbs: false,
                    feature: 'FARMING_REPORTS'
                },
                {
                    id: 'gender-matrix',
                    title: 'Gender Matrix Report',
                    type: 'item',
                    url: '/app/users/gender-matrix',
                    breadcrumbs: false,
                    feature: 'FARMING_REPORTS'
                },
                {
                    id: 'age-matrix',
                    title: 'Age Matrix Report',
                    type: 'item',
                    url: '/app/users/age-matrix',
                    breadcrumbs: false,
                    feature: 'FARMING_REPORTS'
                },
                {
                    id: 'general-oganisation-report',
                    title: 'General Organisation Report',
                    type: 'item',
                    url: '/app/users/general-oganisation-report',
                    breadcrumbs: false,
                    feature: 'OTHER_AGRICULTURAL_ORGANISATIONS_REPORTS'
                }
                // {
                //     id: 'farm-locations-report',
                //     title: 'Locations Report',
                //     type: 'item',
                //     url: '/app/users/farm-locations-report',
                //     breadcrumbs: false,
                //     feature: 'NONE'
                // }
            ]
        },
        // {
        //     id: 'application-report',
        //     title: 'Applications Reports',
        //     type: 'collapse',
        //     icon: IconReportSearch,
        //     systemSubModule: 'NONE',
        //     children: [
        //         {
        //             id: 'application-report',
        //             title: 'Applicant`s Report',
        //             type: 'item',
        //             url: '/app/users/application-report',
        //             breadcrumbs: false,
        //             feature: 'NONE'
        //         }
        //     ]
        // },
        // {
        //     id: 'other-agricultural-oganisation-report',
        //     title: 'Other Agricultural Organisation Reports',
        //     type: 'collapse',
        //     icon: IconReportSearch,
        //     systemSubModule: 'RECORDS',
        //     children: [
        //         {
        //             id: 'other-agricultural-oganisation-report',
        //             title: 'Other Agricultural Organisation Report',
        //             type: 'item',
        //             url: '/app/users/other-agricultural-oganisation-report',
        //             breadcrumbs: false,
        //             feature: 'OTHER_AGRICULTURAL_ORGANISATIONS_REPORTS'
        //         }
        //     ]
        // },
        {
            id: 'production-plan-report',
            title: 'Production Reports',
            type: 'collapse',
            icon: IconReportSearch,
            systemSubModule: 'PLANNING',
            children: [
                {
                    id: 'production-plan-report',
                    title: 'Production Plan Report',
                    type: 'item',
                    url: '/app/users/production-plan-report',
                    breadcrumbs: false,
                    feature: 'PRODUCTION_PLAN_REPORTS'
                }
            ]
        },
        {
            id: 'organisation-or-partner-report',
            title: 'Organisation or Partner Reports',
            type: 'collapse',
            icon: IconReportSearch,
            systemSubModule: 'RECORDS',
            children: [
                {
                    id: 'organisation-or-partner-report',
                    title: 'General Report',
                    type: 'item',
                    url: '/app/users/organisation-or-partner-report',
                    breadcrumbs: false,
                    feature: 'GENERAL_REPORTS'
                },
                {
                    id: 'organisation-or-partner-gender-report',
                    title: 'General Gender Report',
                    type: 'item',
                    url: '/app/users/organisation-or-partner-gender-report',
                    breadcrumbs: false,
                    feature: 'GENERAL_REPORTS'
                },
                {
                    id: 'organisation-or-partner-age-report',
                    title: 'General Age Report',
                    type: 'item',
                    url: '/app/users/organisation-or-partner-age-report',
                    breadcrumbs: false,
                    feature: 'GENERAL_REPORTS'
                }
            ]
        },
        {
            id: 'association-farmer-report',
            title: 'Association Reports',
            type: 'collapse',
            icon: IconUsers,
            systemSubModule: 'RECORDS',
            children: [
                {
                    id: 'association-farmer-report',
                    title: 'Asoociation Farmer Report',
                    type: 'item',
                    url: '/app/users/association-farmer-report',
                    breadcrumbs: false,
                    feature: 'ASSOCIATION_REPORTS'
                }
            ]
        }
    ]
};

export default reportsMenuItems;
