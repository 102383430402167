/* eslint-disable no-unused-vars */
import { lazy } from 'react';

// project imports
import MainLayout from 'layout/MainLayout';
import Loadable from 'ui-component/Loadable';

// dashboard routing
const DashboardDefault = Loadable(lazy(() => import('views/dashboard/Default')));
const AdminDashboard = Loadable(lazy(() => import('views/adim-views/admin-dashboard/Default')));
const StaffDashboard = Loadable(lazy(() => import('views/staff-views/staff-dashboard/Default')));
const AssociationDashboard = Loadable(lazy(() => import('views/association-views/association-dashboard/Default')));
const FarmerDashboard = Loadable(lazy(() => import('views/farmer-views/farmer-dashboard')));
const StaffManagementPage = Loadable(lazy(() => import('views/user-management/staff-management/StaffManagementPage')));
const FarmerManagementPage = Loadable(lazy(() => import('views/user-management/farmer-management/FarmerManagementPage.js')));
const FarmConfigurationPage = Loadable(lazy(() => import('views/farm-management/FarmConfigurationPage')));
const AdminManagementPage = Loadable(lazy(() => import('views/user-management/admin-management/AdminManagementPage')));
const ApplicationManagementPage = Loadable(lazy(() => import('views/user-management/application-mangement/ApplicationManagementPage')));
const AssociationApplicationManagementPage = Loadable(
    lazy(() => import('views/user-management/application-mangement/AssociationApplicationManagementPage'))
);
const UserPermissionPage = Loadable(lazy(() => import('views/user-management/user-permissions/PermissionCombination/UserPermissionsPage')));
const UserPermissionsPage = Loadable(
    lazy(() => import('views/user-management/user-permissions/PermissionCombination/UserPermissionsPage'))
);

const OrganisationPartnerPage = Loadable(
    lazy(() => import('views/settings/association-management/OrganisationOrPartnerConfigurationPage'))
);
const AssignPermissionsPage = Loadable(
    lazy(() => import('views/user-management/user-permissions/assign-permissions/AssignPermissionsPage'))
);
const UserProfilePage = Loadable(lazy(() => import('views/user/user-profile/UserProfilePage')));
const ContractsConfigurationPage = Loadable(lazy(() => import('views/contract-management/ContractsConfigurationPage')));
const ContractsTrackingPage = Loadable(lazy(() => import('views/contract-management/contract-tracking/ContractsTrackingPage')));

// Product management
const ProductionConfigurationPage = Loadable(lazy(() => import('views/product-management/ProductionConfigurationPage')));

const ProductionTrackerPage = Loadable(lazy(() => import('views/product-management/ProductionTrackerPage')));

const ProductionAnalysisPage = Loadable(lazy(() => import('views/product-management/production-analysis/ProductionAnalysis')));

// Product management
const ProductConfigurationPage = Loadable(lazy(() => import('views/product-configuration/ProductConfigurationPage')));

// Price management
const PriceManagement = Loadable(lazy(() => import('views/price-management/PriceConfigurationPage')));

// User Profile management
const FarmerRecordManagement = Loadable(lazy(() => import('views/user-profile-management/UserProfileManagement')));
const FarmerRecordsPage = Loadable(lazy(() => import('views/farmer-profiling/farmer-records/FarmerRecordsPage')));
const FarmerGroupRecordsPage = Loadable(lazy(() => import('views/farmer-profiling/farmer-group-records/FarmerGroupRecordsPage')));
const FarmerGroupRecordsLeadersView = Loadable(
    lazy(() => import('views/farmer-profiling/farmer-group-records/FarmerGroupRecordsLeadersView'))
);
const OtherAgricultureCompaniesPage = Loadable(
    lazy(() => import('views/farmer-profiling/farmer-group-records/OtherAgricultureCompaniesPage'))
);

// In-Development management
const InDevelopmentPage = Loadable(lazy(() => import('views/user-profile-management/InDevelopmentPage')));

// Farmer Price Management
const MarketPriceViewPage = Loadable(lazy(() => import('views/farmer-views/farmer-market-price-view/MarketPriceViewPage')));

// Farmer Price Management
const MarketConfigurationViewPage = Loadable(lazy(() => import('views/price-management/price-configuration/MarketConfigurationViewPage')));

//Farm Inputs
const FarmInputConfigurationPage = Loadable(lazy(() => import('views/farm-input-management/FarmInputConfigurationPage')));

//Farm Equipment
const FarmEquipmentConfigurationPage = Loadable(lazy(() => import('views/mechanized-tools-management/MechanizedToolsConfigurationPage')));

//Buyers Inputs
const BuyerRecordsPage = Loadable(lazy(() => import('views/buyer-profiling/buyer-records/BuyerRecordsPage')));
const BuyerRecordsPageFarmerView = Loadable(lazy(() => import('views/buyer-profiling/buyer-farmer-view/BuyerRecordsPageFarmerView')));

//Buyers Inputs
const SupplierRecordsPage = Loadable(lazy(() => import('views/supplier-profiling/supplier-records/SupplierRecordsPage')));

const SupplierConfiguration = Loadable(lazy(() => import('views/supplier-profiling/supplier-configuration/SupplierConfiguration')));

//Association Records
const AssociationRecordsPage = Loadable(lazy(() => import('views/association-management/AssociationConfigurationPage')));

//Association Records
const BroadcastConfigurationPage = Loadable(lazy(() => import('views/broadcast-management/BroadcastConfigurationPage')));

const MyBroadcastPage = Loadable(lazy(() => import('views/broadcast-management/MyBroadcastPage')));

// Reports Management
const EquipmentReportPage = Loadable(lazy(() => import('views/reports/ProfilingReports/FarmEquipmentReports')));
const FarmInputReportPage = Loadable(lazy(() => import('views/reports/ProfilingReports/FarmInputReports')));
const ProductionPlanReportPage = Loadable(lazy(() => import('views/reports/ProductionPlanReports/ProductionPlanReports')));
const OtherAgriculturalOrganisationPageReports = Loadable(
    lazy(() => import('views/reports/MemberReport/OtherAgriculturalOrganisationPageReports'))
);
const GeneralOrganisationPartnerReportPage = Loadable(
    lazy(() => import('views/reports/OrganisationOrPartnerReports/GeneralOrganisationPartnerReports'))
);
const GeneralOrganisationPartnerGenderReportPage = Loadable(
    lazy(() => import('views/reports/OrganisationOrPartnerReports/GeneralGenderReport/GeneralOrganisationPartnerGenderReports'))
);
const GeneralOrganisationPartnerAgeReportPage = Loadable(
    lazy(() => import('views/reports/OrganisationOrPartnerReports/GeneralAgeReport/GeneralOrganisationPartnerAgeReports'))
);
const AssociationFarmerReportPage = Loadable(lazy(() => import('views/reports/AssociationReports/AssociationFarmerReports')));
const ProductReportPage = Loadable(lazy(() => import('views/reports/ProfilingReports/FarmProductsReports')));
const GenderMetrixReports = Loadable(lazy(() => import('views/reports/ProfilingReports/GenderMetrixReports')));
const AgeMetrixReports = Loadable(lazy(() => import('views/reports/ProfilingReports/AgeMetrixReports')));

// Contract Opportunities
// const ContractOppotunities = Loadable(lazy(() => import('views/farmer-views/farmer-contract-view/ContractOppotunitiesPage')));

const QuotationListFarmerView = Loadable(lazy(() => import('views/product-management/Quotation/QuotationListFarmerView')));

const MyContractOppotunities = Loadable(lazy(() => import('views/farmer-views/farmer-contract-view/MyContractsConfigurationPage')));

// Production Plan
const ProductionPlanReportPageIndividualView = Loadable(
    lazy(() => import('views/product-management/ProductionConfigurationPageIndividualView'))
);
// Settings
const DFASettings = Loadable(lazy(() => import('views/organisation-management/OrganisationConfigurationPage')));
// utilities routing
// const UtilsTypography = Loadable(lazy(() => import('views/utilities/Typography')));
// const UtilsColor = Loadable(lazy(() => import('views/utilities/Color')));
// const UtilsShadow = Loadable(lazy(() => import('views/utilities/Shadow')));
// const UtilsMaterialIcons = Loadable(lazy(() => import('views/utilities/MaterialIcons')));
// const UtilsTablerIcons = Loadable(lazy(() => import('views/utilities/TablerIcons')));

// sample page routing
// const SamplePage = Loadable(lazy(() => import('views/sample-page')));
import { AuthGuard } from './AuthWrappers/AuthGuard';
import { useAuth } from 'hooks/useAuth';
import { currentUserUtils } from 'utils/currentUserUtils';

// ==============================|| MAIN ROUTING ||============================== //

// const MainRoutes = userBasedNavigationRoutes();

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: 'app/staff',
            children: [
                {
                    path: 'dashboard',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <StaffDashboard />
                        </AuthGuard>
                    )
                }
            ]
        },
        {
            path: 'app/association',
            children: [
                {
                    path: 'dashboard',
                    element: (
                        <AuthGuard
                            roleType={'ASSOCIATION'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <AssociationDashboard />
                        </AuthGuard>
                    )
                },
                {
                    path: 'dfa-applications',
                    element: (
                        <AuthGuard
                            roleType={'ASSOCIATION'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <ApplicationManagementPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'association-farmer-records',
                    element: (
                        <AuthGuard
                            roleType={'ASSOCIATION'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <FarmerRecordsPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'association-farmer-groups',
                    element: (
                        <AuthGuard
                            roleType={'ASSOCIATION'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <FarmerGroupRecordsPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'association-production-plan',
                    element: (
                        <AuthGuard
                            roleType={'ASSOCIATION'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <ProductionConfigurationPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'association-production-tracker',
                    element: (
                        <AuthGuard
                            roleType={'ASSOCIATION'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <ProductionTrackerPage />
                        </AuthGuard>
                    )
                },
                // {
                //     path: 'association-production-analysis',
                //     element: (
                //         <AuthGuard
                //             roleType={'ASSOCIATION'}
                //             permissions={{
                //                 systemModule: 'NONE',
                //                 systemSubModule: 'NONE',
                //                 feature: 'NONE'
                //             }}
                //         >
                //             <ProductionAnalysisPage />
                //         </AuthGuard>
                //     )
                // },
                {
                    path: 'association-contracts-management',
                    element: (
                        <AuthGuard
                            roleType={'ASSOCIATION'}
                            permissions={{
                                systemModule: 'CONTRACT_MANAGEMENT',
                                systemSubModule: 'CONTRACT',
                                feature: 'CONTRACT'
                            }}
                        >
                            <ContractsConfigurationPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'association-service-providers',
                    element: (
                        <AuthGuard
                            roleType={'ASSOCIATION'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <SupplierRecordsPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'association-broadcast',
                    element: (
                        <AuthGuard
                            roleType={'ASSOCIATION'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <BroadcastConfigurationPage />
                        </AuthGuard>
                    )
                }
            ]
        },
        {
            path: 'app/admin',
            children: [
                {
                    path: 'dashboard',
                    element: (
                        <AuthGuard
                            roleType={'ADMINISTRATOR'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <AdminDashboard />
                        </AuthGuard>
                    )
                },
                {
                    path: 'contracts/contracts-configuration',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'CONTRACT_MANAGEMENT',
                                systemSubModule: 'CONTRACT',
                                feature: 'CONTRACT'
                            }}
                        >
                            <ContractsConfigurationPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'contracts/contracts-tracking',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'CONTRACT_MANAGEMENT',
                                systemSubModule: 'CONTRACT',
                                feature: 'CONTRACT'
                            }}
                        >
                            <ContractsTrackingPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'contracts/contracts-management',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <InDevelopmentPage />
                        </AuthGuard>
                    )
                }
            ]
        },
        {
            path: 'app/users',
            children: [
                {
                    path: 'staff-management',
                    element: (
                        <AuthGuard
                            roleType={'ADMINISTRATOR'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <StaffManagementPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'admin-management',
                    element: (
                        <AuthGuard
                            roleType={'ADMINISTRATOR'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <AdminManagementPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'farmer-management',
                    element: (
                        <AuthGuard
                            roleType={'ADMINISTRATOR'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <FarmerManagementPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'user-permissions',
                    element: (
                        <AuthGuard
                            roleType={'ADMINISTRATOR'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <UserPermissionsPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'dfa-settings',
                    element: (
                        <AuthGuard
                            roleType={'ADMINISTRATOR'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <InDevelopmentPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'user-permissions/assignment',
                    element: (
                        <AuthGuard
                            roleType={'ADMINISTRATOR'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <AssignPermissionsPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'organistion-partner-management/organistion-partner',
                    element: (
                        <AuthGuard
                            roleType={'ADMINISTRATOR'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <OrganisationPartnerPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'applications-management',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <ApplicationManagementPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'national-application',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <AssociationApplicationManagementPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'farmer-records',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'RECORDS_MANAGEMENT',
                                systemSubModule: 'RECORDS',
                                feature: 'FARMERS_RECORDS'
                            }}
                        >
                            <FarmerRecordsPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'farmer-groups',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'RECORDS_MANAGEMENT',
                                systemSubModule: 'RECORDS',
                                feature: 'FARMER_GROUP_RECORDS'
                            }}
                        >
                            <FarmerGroupRecordsPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'other-agriculture-companies',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'RECORDS_MANAGEMENT',
                                systemSubModule: 'RECORDS',
                                feature: 'FARMER_GROUP_RECORDS'
                            }}
                        >
                            <OtherAgricultureCompaniesPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'farm-management',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'RECORDS_MANAGEMENT',
                                systemSubModule: 'RECORDS',
                                feature: 'FARMING_MANAGEMENT'
                            }}
                        >
                            <FarmConfigurationPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'exit-management',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'RECORDS_MANAGEMENT',
                                systemSubModule: 'RECORDS',
                                feature: 'EXIT_MANAGEMENT'
                            }}
                        >
                            <InDevelopmentPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'product-management',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'PRODUCT',
                                systemSubModule: 'PRODUCT_RECORDS',
                                feature: 'PRODUCTS_PROFILING'
                            }}
                        >
                            <ProductConfigurationPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'manage-value-chains',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'PRODUCT',
                                systemSubModule: 'PRODUCT_RECORDS',
                                feature: 'PRODUCTS_PROFILING'
                            }}
                        >
                            <InDevelopmentPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'price-settings',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'MARKET_ACCESS',
                                systemSubModule: 'MANAGE_MARKET_PRICES',
                                feature: 'MARKET_PRICES_INVENTORY'
                            }}
                        >
                            <PriceManagement />
                        </AuthGuard>
                    )
                },
                {
                    path: 'price-analysis',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'MARKET_ACCESS',
                                systemSubModule: 'MANAGE_MARKET_PRICES',
                                feature: 'MARKET_PRICES_ANALYSIS'
                            }}
                        >
                            <MarketPriceViewPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'market-configuration',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'MARKET_ACCESS',
                                systemSubModule: 'MANAGE_MARKET_PRICES',
                                feature: 'MARKET_PRICES_ANALYSIS'
                            }}
                        >
                            <MarketConfigurationViewPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'equipments-report',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'SYSTEM_REPORTS',
                                systemSubModule: 'RECORDS',
                                feature: 'FARMING_REPORTS'
                            }}
                        >
                            <EquipmentReportPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'inputs-report',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'SYSTEM_REPORTS',
                                systemSubModule: 'RECORDS',
                                feature: 'FARMING_REPORTS'
                            }}
                        >
                            <FarmInputReportPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'production-plan',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'FARM_TO_FORK_MANAGEMENT',
                                systemSubModule: 'PLANNING',
                                feature: 'PRODUCTION_PLAN'
                            }}
                        >
                            <ProductionConfigurationPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'production-tracker',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'FARM_TO_FORK_MANAGEMENT',
                                systemSubModule: 'PLANNING',
                                feature: 'PRODUCTION_PLAN'
                            }}
                        >
                            <ProductionTrackerPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'production-analysis',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'FARM_TO_FORK_MANAGEMENT',
                                systemSubModule: 'PLANNING',
                                feature: 'PRODUCTION_PLAN'
                            }}
                        >
                            <ProductionAnalysisPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'general-oganisation-report',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'SYSTEM_REPORTS',
                                systemSubModule: 'RECORDS',
                                feature: 'OTHER_AGRICULTURAL_ORGANISATIONS_REPORTS'
                            }}
                        >
                            <OtherAgriculturalOrganisationPageReports dashboardView={false} />
                        </AuthGuard>
                    )
                },
                {
                    path: 'production-plan-report',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'SYSTEM_REPORTS',
                                systemSubModule: 'PLANNING',
                                feature: 'PRODUCTION_PLAN_REPORTS'
                            }}
                        >
                            <ProductionPlanReportPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'organisation-or-partner-report',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'SYSTEM_REPORTS',
                                systemSubModule: 'RECORDS',
                                feature: 'GENERAL_REPORTS'
                            }}
                        >
                            <GeneralOrganisationPartnerReportPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'organisation-or-partner-gender-report',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'SYSTEM_REPORTS',
                                systemSubModule: 'RECORDS',
                                feature: 'GENERAL_REPORTS'
                            }}
                        >
                            <GeneralOrganisationPartnerGenderReportPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'organisation-or-partner-age-report',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'SYSTEM_REPORTS',
                                systemSubModule: 'RECORDS',
                                feature: 'GENERAL_REPORTS'
                            }}
                        >
                            <GeneralOrganisationPartnerAgeReportPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'association-farmer-report',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'SYSTEM_REPORTS',
                                systemSubModule: 'RECORDS',
                                feature: 'ASSOCIATION_REPORTS'
                            }}
                        >
                            <AssociationFarmerReportPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'farm-inputs',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'RECORDS_MANAGEMENT',
                                systemSubModule: 'INPUTS_RECORDS',
                                feature: 'FARM_INPUTS_PROFILING'
                            }}
                        >
                            <FarmInputConfigurationPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'association-farmer-report',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <AssociationFarmerReportPage />
                        </AuthGuard>
                    )
                },

                {
                    path: 'farm-equipment',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'RECORDS_MANAGEMENT',
                                systemSubModule: 'EQUIPMENT_RECORDS',
                                feature: 'FARM_EQUIPMENT_PROFILING'
                            }}
                        >
                            <FarmEquipmentConfigurationPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'farm-products-report',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'SYSTEM_REPORTS',
                                systemSubModule: 'RECORDS',
                                feature: 'FARMING_REPORTS'
                            }}
                        >
                            <ProductReportPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'gender-matrix',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'SYSTEM_REPORTS',
                                systemSubModule: 'RECORDS',
                                feature: 'FARMING_REPORTS'
                            }}
                        >
                            <GenderMetrixReports />
                        </AuthGuard>
                    )
                },
                {
                    path: 'age-matrix',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'SYSTEM_REPORTS',
                                systemSubModule: 'RECORDS',
                                feature: 'FARMING_REPORTS'
                            }}
                        >
                            <AgeMetrixReports />
                        </AuthGuard>
                    )
                },
                {
                    path: 'manage-buyers',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <BuyerRecordsPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'manage-supplier',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <SupplierRecordsPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'manage-supplier-categories',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <SupplierConfiguration />
                        </AuthGuard>
                    )
                },
                {
                    path: 'dfa-records',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'RECORDS_MANAGEMENT',
                                systemSubModule: 'RECORDS',
                                feature: 'ASSOCIATION_RECORDS'
                            }}
                        >
                            <AssociationRecordsPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'system-broadcast',
                    element: (
                        <AuthGuard
                            roleType={'STAFF'}
                            permissions={{
                                systemModule: 'UTILITIES_MANAGEMENT',
                                systemSubModule: 'COMMUNICATION',
                                feature: 'BROADCAST'
                            }}
                        >
                            <BroadcastConfigurationPage />
                        </AuthGuard>
                    )
                }
            ]
        },
        {
            path: 'app/farmer',
            children: [
                {
                    path: 'dashboard',
                    element: (
                        <AuthGuard
                            roleType={'FARMER'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <FarmerDashboard />
                        </AuthGuard>
                    )
                },
                {
                    path: 'farmer-group/farmer-group-details',
                    element: (
                        <AuthGuard
                            roleType={'FARMER'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <FarmerGroupRecordsLeadersView />
                        </AuthGuard>
                    )
                },
                {
                    path: 'customers/my-customers',
                    element: (
                        <AuthGuard
                            roleType={'FARMER'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <BuyerRecordsPageFarmerView />
                        </AuthGuard>
                    )
                },
                {
                    path: 'market/prices',
                    element: (
                        <AuthGuard
                            roleType={'FARMER'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <MarketPriceViewPage />
                        </AuthGuard>
                    )
                },
                {
                    path: 'contracts/my-contracts',
                    element: (
                        <AuthGuard
                            roleType={'FARMER'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <MyContractOppotunities />
                        </AuthGuard>
                    )
                },
                {
                    path: 'contract/opportunities',
                    element: (
                        <AuthGuard
                            roleType={'FARMER'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <QuotationListFarmerView />
                        </AuthGuard>
                    )
                },
                {
                    path: 'production-plan/my-production-plan',
                    element: (
                        <AuthGuard
                            roleType={'FARMER'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <ProductionPlanReportPageIndividualView />
                        </AuthGuard>
                    )
                },
                {
                    path: 'farmer-broadcast',
                    element: (
                        <AuthGuard
                            roleType={'FARMER'}
                            permissions={{
                                systemModule: 'NONE',
                                systemSubModule: 'NONE',
                                feature: 'NONE'
                            }}
                        >
                            <MyBroadcastPage />
                        </AuthGuard>
                    )
                }
            ]
        },
        {
            path: 'app/user/my-profile',
            element: (
                <UserProfilePage
                    permissions={{
                        systemModule: 'NONE',
                        systemSubModule: 'NONE',
                        feature: 'NONE'
                    }}
                />
            )
        },
        {
            path: 'app/sample-page',
            element: <h1>here</h1>
        }
    ]
};

export default MainRoutes;
