// assets
import { IconSeeding } from '@tabler/icons';

// constant

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const adminMarketPricesProfilingMenuItems = {
    id: 'market-prices-root',
    title: 'Market Prices',
    type: 'group',
    systemModule: 'MARKET_ACCESS',
    children: [
        {
            id: 'market-prices',
            title: 'Market Prices',
            type: 'collapse',
            icon: IconSeeding,
            systemSubModule: 'MANAGE_MARKET_PRICES',
            children: [
                {
                    id: 'price-settings',
                    title: 'Price settings',
                    type: 'item',
                    url: '/app/users/price-settings',
                    breadcrumbs: false,
                    feature: 'MARKET_PRICES_INVENTORY'
                },
                {
                    id: 'price-analysis',
                    title: 'Price analysis',
                    type: 'item',
                    url: '/app/users/price-analysis',
                    breadcrumbs: false,
                    feature: 'MARKET_PRICES_ANALYSIS'
                },
                {
                    id: 'market-configuration',
                    title: 'Market Configuration',
                    type: 'item',
                    url: '/app/users/market-configuration',
                    breadcrumbs: false,
                    feature: 'MARKET_PRICES_ANALYSIS'
                }
            ]
        }
    ]
};

export default adminMarketPricesProfilingMenuItems;
