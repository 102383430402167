// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconFriends, IconLockAccess, IconBriefcase } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconLockAccess,
    IconFriends
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const partnerMenuItems = {
    id: 'partner-records',
    title: 'Partner Profiling',
    type: 'group',
    systemModule: 'RECORDS_MANAGEMENT',
    children: [
        {
            id: 'partner-records',
            title: 'Partner Profiling',
            type: 'collapse',
            icon: IconBriefcase,
            systemSubModule: 'RECORDS',
            children: [
                {
                    id: 'manage-buyers',
                    title: 'Manage Buyers',
                    type: 'item',
                    url: '/app/users/manage-buyers',
                    breadcrumbs: false,
                    feature: 'BUYER_RECORDS'
                },
                {
                    id: 'manage-supplier',
                    title: 'Service Providers',
                    type: 'item',
                    url: '/app/users/manage-supplier',
                    breadcrumbs: false,
                    feature: 'SUPPLIER_RECORDS'
                },
                {
                    id: 'manage-supplier-categories',
                    title: 'Supplier Categories',
                    type: 'item',
                    url: '/app/users/manage-supplier-categories',
                    breadcrumbs: false,
                    feature: 'SUPPLIER_RECORDS'
                }
            ]
        }
    ]
};

export default partnerMenuItems;
