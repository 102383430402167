// material-ui
import { Typography } from '@mui/material';

// project imports
import NavGroup from './NavGroup';
import { userBasedNavigationMenuItems } from 'menu-items';
import { userPermissionUtils } from 'utils/currentUserUtils';

// ==============================|| SIDEBAR MENU LIST ||============================== //

const MenuList = () => {
    const hasRequiredPermissions = (systemModule) => {
        if (systemModule === 'NONE') {
            return true;
        }
        const perms = userPermissionUtils().find((perm) => perm?.systemModule === systemModule);
        return perms !== undefined ? true : false;
    };

    const navItems = userBasedNavigationMenuItems().map((item) => {
        switch (item.type) {
            case 'group':
                return hasRequiredPermissions(item.systemModule) && <NavGroup key={item.id} item={item} />;
            default:
                return (
                    <Typography key={item.id} variant="h6" color="error" align="center">
                        Menu Items Error
                    </Typography>
                );
        }
    });

    return <>{navItems}</>;
};

export default MenuList;
