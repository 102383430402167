import { useRoutes, useLocation } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import { useEffect } from 'react';
// ==============================|| ROUTING RENDER ||============================== //
export default function ThemeRoutes() {
    const location = useLocation();

    // eslint-disable-next-line prettier/prettier
    useEffect(() => { }, [location.key]);
    return useRoutes([MainRoutes, AuthenticationRoutes]);
}
