import { API_BASE_URL, ACCESS_TOKEN } from '../constants';
import secureLocalStorage from 'react-secure-storage';

const request = async (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json'
    });

    if (secureLocalStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + secureLocalStorage.getItem(ACCESS_TOKEN));
    }

    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);
    const response = await fetch(options.url, options);
    const json = await response.json();
    if (!response.ok) {
        return Promise.reject(json);
    }
    return json;
};

export function getPermissionSet() {
    return request({
        url: API_BASE_URL + 'permission-management/get-permission-set',
        method: 'GET'
    });
}
export function getSystemModules() {
    return request({
        url: API_BASE_URL + 'permission-management/get-system-modules',
        method: 'GET'
    });
}
export function getSystemSubModules() {
    return request({
        url: API_BASE_URL + 'permission-management/get-system-sub-modules',
        method: 'GET'
    });
}
export function getSystemFeatures() {
    return request({
        url: API_BASE_URL + 'permission-management/get-features',
        method: 'GET'
    });
}
export function getActionPermission() {
    return request({
        url: API_BASE_URL + 'permission-management/get-action-permission',
        method: 'GET'
    });
}
export function createOrUpdatePermissionSet(params) {
    return request({
        url: API_BASE_URL + 'permission-management/create-update-permission-set',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function deletePermissionSet(params) {
    return request({
        url: API_BASE_URL + 'permission-management/delete-permission-set',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function getUsersPermission(params) {
    return request({
        url: API_BASE_URL + 'permission-management/get-users-permission',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function getSingleUserPermissions() {
    return request({
        url: API_BASE_URL + 'permission-management/get-assigned-permissions',
        method: 'GET'
    });
}
export function assignUserPermission(params) {
    return request({
        url: API_BASE_URL + 'permission-management/assign-user-permission',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function unassignUserPermission(params) {
    return request({
        url: API_BASE_URL + 'permission-management/unassign-user-permission',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function getAccessLevel() {
    return request({
        url: API_BASE_URL + 'account-management/access_level',
        method: 'GET'
    });
}
export function attachAccessLevel(params) {
    return request({
        url: API_BASE_URL + 'account-management/grant-access-level',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function getUsersAccessLevel(params) {
    return request({
        url: API_BASE_URL + 'account-management/get-user-access-levels',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function deleteAccessLevel(params) {
    return request({
        url: API_BASE_URL + 'account-management/delete-access-level',
        method: 'DELETE',
        body: JSON.stringify(params)
    });
}
