import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
import config from 'config';
import UnffeLogo from 'ui-component/Logo';
import FarmGainLogo from 'ui-component/FarmGainLogo';
import UCULogo from 'ui-component/UCULogo';
import SyovaLogo from 'ui-component/SyovaLogo';
import secureLocalStorage from 'react-secure-storage';

// ==============================|| MAIN LOGO ||============================== //
const staffType = secureLocalStorage.getItem('staffType');
const LogoSection = () => (
    <ButtonBase disableRipple component={Link} to={config.defaultPath}>
        {staffType !== null && staffType === 'FARMGAIN' ? (
            <FarmGainLogo width={170} />
        ) : staffType === 'UCU' ? (
            <UCULogo width={170} />
        ) : staffType === 'SYOVA' ? (
            <SyovaLogo width={170} />
        ) : (
            <UnffeLogo width={170} />
        )}
    </ButtonBase>
);

export default LogoSection;
