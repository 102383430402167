// assets
import { IconDashboard } from '@tabler/icons';

// constant
const icons = { IconDashboard };

// ==============================|| DASHBOARD MENU ITEMS ||============================== //

const farmerDashboard = {
    id: 'dashboard',
    title: 'Dashboard',
    type: 'group',
    systemModule: 'NONE',
    children: [
        {
            id: 'default',
            title: 'Dashboard',
            type: 'item',
            url: '/app/farmer/dashboard',
            icon: icons.IconDashboard,
            breadcrumbs: false,
            feature: 'NONE'
        }
    ]
};

export default farmerDashboard;
