// Hook (use-auth.js)
import React, { useState, useEffect, useContext, createContext } from 'react';
import { loginRequest } from '../utils/APIUtils';
import { useNavigate, useLocation } from 'react-router-dom';
import { ACCESS_TOKEN } from '../constants';
import jwtDecode from 'jwt-decode';
import { currentUserUtils } from 'utils/currentUserUtils';
import { getSingleUserPermissions } from 'utils/PermissionUtils';
import secureLocalStorage from 'react-secure-storage';
const authContext = createContext();
// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return <authContext.Provider value={auth}>{children}</authContext.Provider>;
}
// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useAuth = () => {
    return useContext(authContext);
};
// Provider hook that creates auth object and handles state
function useProvideAuth() {
    const [user, setUser] = useState(null);
    const [authed, setAuthed] = React.useState(false);
    const navigate = useNavigate();
    const { pathname } = useLocation();

    const handleGetSingleUserPermissions = () => {
        getSingleUserPermissions()
            .then((response) => {
                secureLocalStorage.setItem('roles', JSON.stringify(response[0].permissionsAssigned));
                routeSelector();
            })
            .catch((error) => {
                // console.log('erer', error);
            });
    };
    const handleRedirect = () => {
        setAuthed(isValidAccessToken());
        if (isValidAccessToken()) {
            navigate(pathname ?? '/app');
        } else {
            console.log('path>', pathname);
            if (pathname !== '/app/register' && pathname !== '/app/login') {
                navigate('/app/home');
            }
        }
    };

    const isValidAccessToken = () => {
        try {
            let token = secureLocalStorage.getItem('accessToken');
            token = jwtDecode(token);
            return !!token && Date.now() <= token.exp * 1000;
        } catch (e) {
            return false;
        }
    };

    const routeSelector = () => {
        if (pathname === '/app/login') {
            switch (currentUserUtils()?.userType) {
                case 'STAFF':
                    navigate('/app/staff/dashboard', { replace: true });
                    break;
                case 'ADMINISTRATOR':
                    navigate('/app/admin/dashboard', { replace: true });
                    break;
                case 'FARMER':
                    navigate('/app/farmer/dashboard', { replace: true });
                    break;
                case 'SUPPLIER':
                    navigate('/app/admin/dashboard', { replace: true });
                    break;
                case 'PARTNER':
                    navigate('/app/admin/dashboard', { replace: true });
                    break;
                case 'APPLICANT_FARMER':
                    navigate('/app/admin/dashboard', { replace: true });
                    break;
                case 'APPLICANT_SUPPLIER':
                    navigate('/app/admin/dashboard', { replace: true });
                    break;
                case 'ASSOCIATION':
                    navigate('/app/association/dashboard', { replace: true });
                    break;

                default:
                    break;
            }
        } else {
            navigate(pathname ?? '/app');
        }
    };
    const login = (usernameOrEmail, password) => {
        loginRequest({ usernameOrEmail, password })
            .then((response) => {
                secureLocalStorage.setItem(ACCESS_TOKEN, response.accessToken);
                const userDetails = {
                    userType: response.userType,
                    name: response.name,
                    email: response.email,
                    profilePicture: response.profilePicture
                };

                secureLocalStorage.setItem('currentUser', JSON.stringify(userDetails));
                secureLocalStorage.setItem('staffType', response?.staffType);
                setUser(userDetails);
                setAuthed(isValidAccessToken());

                handleGetSingleUserPermissions();
            })
            .catch((error) => {
                console.log('err', error);
                alert('wrong pass');
            });
    };

    const logout = async () => {
        setUser(false);
        setAuthed(false);
        navigate('/app/login', { state: { path: pathname }, replace: true });
        secureLocalStorage.clear();
    };
    console.log('authed', authed);
    useEffect(() => {
        authed && handleGetSingleUserPermissions();
        handleRedirect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [authed, user]);
    // Return the user object and auth methods
    return {
        user,
        authed,
        login,
        logout
    };
}
