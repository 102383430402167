// assets
import {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconFriends,
    IconLockAccess,
    IconBriefcase,
    IconFileSettings
} from '@tabler/icons';

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const associationUtilitiesMenuItems = {
    id: 'association-utilities',
    title: 'Utilities',
    type: 'group',
    systemModule: 'NONE',
    children: [
        {
            id: 'association-information-source',
            title: 'Information Source',
            type: 'collapse',
            icon: IconFileSettings,
            systemSubModule: 'NONE',
            children: [
                {
                    id: 'association-service-providers',
                    title: 'Service Providers',
                    type: 'item',
                    url: '/app/association/association-service-providers',
                    breadcrumbs: false,
                    feature: 'NONE'
                }
                // {
                //     id: 'association-management',
                //     title: 'Contracts Management',
                //     type: 'item',
                //     url: '/app/admin/contracts/contracts-management',
                //     breadcrumbs: false,
                //     feature: 'NONE'
                // }
            ]
        },
        {
            id: 'association-information-share',
            title: 'Messages',
            type: 'collapse',
            icon: IconBriefcase,
            systemSubModule: 'NONE',
            children: [
                {
                    id: 'association-broadcast',
                    title: 'Broadcasts',
                    type: 'item',
                    url: '/app/association/association-broadcast',
                    breadcrumbs: false,
                    feature: 'NONE'
                }
            ]
        }
    ]
};

export default associationUtilitiesMenuItems;
