// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconFriends } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconFriends
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const farmerGroupDetailsMenuItems = {
    id: 'farmer-group-management',
    title: 'Group Management',
    type: 'group',
    systemModule: 'NONE',
    children: [
        {
            id: 'farmer-group-infomation',
            title: 'Group Information',
            type: 'collapse',
            icon: icons.IconFriends,
            systemSubModule: 'NONE',
            children: [
                {
                    id: 'farmer-group-details',
                    title: 'Farmer Group',
                    type: 'item',
                    url: 'app/farmer/farmer-group/farmer-group-details',
                    breadcrumbs: false,
                    feature: 'NONE'
                }
            ]
        }
    ]
};

export default farmerGroupDetailsMenuItems;
