// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconFriends, IconLockAccess, IconBriefcase } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconLockAccess,
    IconFriends
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const staffUtilitiesMenuItems = {
    id: 'farmer-utilities',
    title: 'Utilities',
    type: 'group',
    systemModule: 'NONE',
    children: [
        {
            id: 'farmer-information-share',
            title: 'Messages',
            type: 'collapse',
            icon: IconBriefcase,
            systemSubModule: 'NONE',
            children: [
                {
                    id: 'farmer-broadcast',
                    title: 'Broadcasts',
                    type: 'item',
                    url: '/app/farmer/farmer-broadcast',
                    breadcrumbs: false,
                    feature: 'NONE'
                }
            ]
        }
    ]
};

export default staffUtilitiesMenuItems;
