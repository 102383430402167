import dashboard from './dashboard';
import pages from './pages';
import utilities from './utilities';
import other from './other';
import staffDashboard from './staff-menu-items/staffDashboard';
import staffApplicationMenuItems from './staff-menu-items/staffApplicationMenuItems';
import staffUtilitiesMenuItems from './staff-menu-items/staffGeneralUtilitiesMenuItems';
import adminDashboard from './admin-menu-items/adminDashboard';
import farmerDashboard from './farmer-menu-items/farmerDashboard';
import adminMenuItems from './admin-menu-items/adminMenuItems';
import adminContractMgtMenuItems from './admin-menu-items/adminContractMgtMenuItems';
import adminProductProfilingMenuItems from './admin-menu-items/adminProductProfilingMenuItems';
import adminMarketPricesProfilingMenuItems from './admin-menu-items/adminMarketPricesProfilingMenuItems';
import staffReportsMenuItems from './staff-menu-items/staffReportsMenuItems';
import partnerMenuItems from './buyer-menu-items/partnerMenuItems';
import adminFarmerProfilingMenuItems from './admin-menu-items/adminFarmerProfilingMenuItems';
import adminFarmInputMenuItems from './admin-menu-items/adminFarmInputMenuItems';
import adminEquipmentMenuItems from './admin-menu-items/adminEquipmentMenuItems';
import farmerMenuItems from './farmer-menu-items/farmerMenuItems';
import farmerGeneralUtilitiesMenuItems from './farmer-menu-items/farmerGeneralUtilitiesMenuItems';
import farmerMarketMenuItems from './farmer-menu-items/farmerMarketMenuItems';
import farmerContractsMenuItems from './farmer-menu-items/farmerContractsMenuItems';
import farmerProductionPlanMenuItems from './farmer-menu-items/farmerProductionPlanMenuItems';
import farmerMiscMenuItems from './farmer-menu-items/farmerMiscMenuItems';
import farmerGroupDetailsMenuItems from './farmer-menu-items/farmerGroupDetailsMenuItems';
import { currentUserUtils } from 'utils/currentUserUtils';
import adminProductionPlanMenuItems from './admin-menu-items/adminProductionPlanMenuItems';
import associationMenuItems from './association-menu-items/associationMenuItems';
import associationApplicationsMenuItems from './association-menu-items/associationApplicationsMenuItems';
import associationGeneralUtilitiesMenuItems from './association-menu-items/associationGeneralUtilitiesMenuItems';
import associationFarmerProfilingMenuItems from './association-menu-items/associationFarmerProfilingMenuItems';
import associationProductionPlanMenuItems from './association-menu-items/associationProductionPlanMenuItems';
import associationContractMgtMenuItems from './association-menu-items/associationContractMgtMenuItems';

// ==============================|| MENU ITEMS ||============================== //
// STAFF(0),
// 	ADMINISTRATOR(1),
// 	FARMER(2),
// 	BUYER(3),
// 	SUPPLIER(4),
// 	PARTNER(5),
// 	APPLICANT_FARMER(6),
// 	APPLICANT_SUPPLIER(7);
export function userBasedNavigationMenuItems() {
    let navItems = [];
    switch (currentUserUtils()?.userType) {
        case 'ADMINISTRATOR':
            navItems = [adminDashboard, adminMenuItems];
            break;
        case 'FARMER':
            navItems = [
                farmerDashboard,
                farmerGroupDetailsMenuItems,
                farmerMenuItems,
                farmerContractsMenuItems,
                farmerProductionPlanMenuItems,
                farmerGeneralUtilitiesMenuItems
            ];
            break;
        case 'STAFF':
            navItems = [
                staffDashboard,
                staffApplicationMenuItems,
                // adminFarmerProfilingMenuItems,
                adminProductProfilingMenuItems,
                //adminFarmInputMenuItems,
                //adminEquipmentMenuItems,
                partnerMenuItems,
                adminProductionPlanMenuItems,
                adminContractMgtMenuItems,
                adminMarketPricesProfilingMenuItems,
                staffReportsMenuItems,
                staffUtilitiesMenuItems
            ];
            break;
        case 'SUPPLIER':
            navItems = [staffDashboard];
            break;
        case 'PARTNER':
            navItems = [staffDashboard];
            break;
        case 'APPLICANT_FARMER':
            navItems = [staffDashboard];
            break;
        case 'APPLICANT_SUPPLIER':
            navItems = [staffDashboard];
            break;
        case 'ASSOCIATION':
            navItems = [
                associationMenuItems,
                associationApplicationsMenuItems,
                associationFarmerProfilingMenuItems,
                associationProductionPlanMenuItems,
                associationContractMgtMenuItems,
                associationGeneralUtilitiesMenuItems
            ];
            break;

        default:
            break;
    }
    return navItems;
}
