const UNNFE_ENV = 'production';
//const UNNFE_ENV = 'development';

export const ACCESS_TOKEN = 'accessToken';
export const API_BASE_URL = UNNFE_ENV === 'production' ? 'https://airtea-mis.ucu.ac.ug/unffe-api/' : 'http://localhost:5020/unffe-api/';
export const APPLICATION_SERVICE_API_URL =
    UNNFE_ENV === 'production' ? 'https://airtea-mis.ucu.ac.ug/unffe-application/' : 'http://localhost:5030/unffe-application/';

export const FILE_API_BASE_URL =
    UNNFE_ENV === 'production' ? 'https://airtea-mis.ucu.ac.ug/file-manager' : 'http://localhost:8400/file-manager';
