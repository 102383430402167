// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconFriends, IconLockAccess, IconFileReport } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconLockAccess,
    IconFriends,
    IconFileReport
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const associationContractMgtMenuItems = {
    id: 'association-contracts-management',
    title: 'Contract Management',
    type: 'group',
    systemModule: 'NONE',
    children: [
        {
            id: 'association-contracts-management',
            title: 'Contract Management',
            type: 'collapse',
            icon: icons.IconFileReport,
            systemSubModule: 'NONE',
            children: [
                {
                    id: 'association-contracts-management',
                    title: 'Contracts',
                    type: 'item',
                    url: '/app/association/association-contracts-management',
                    breadcrumbs: false,
                    feature: 'NONE'
                }
                // {
                //     id: 'association-management',
                //     title: 'Contracts Management',
                //     type: 'item',
                //     url: '/app/admin/contracts/contracts-management',
                //     breadcrumbs: false,
                //     feature: 'NONE'
                // }
            ]
        }
    ]
};

export default associationContractMgtMenuItems;
