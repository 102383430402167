// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconFriends, IconBriefcase } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconFriends
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const farmerMenuItems = {
    id: 'farm-management',
    title: 'Market Infromation',
    type: 'group',
    systemModule: 'NONE',
    children: [
        {
            id: 'farmer-market-prices',
            title: 'Market Prices',
            type: 'collapse',
            icon: IconBriefcase,
            systemSubModule: 'NONE',
            children: [
                {
                    id: 'prices-market',
                    title: 'Price analysis',
                    type: 'item',
                    url: 'app/farmer/market/prices',
                    breadcrumbs: false,
                    feature: 'NONE'
                }
            ]
        },
        {
            id: 'customer-management',
            title: 'Buyer Records',
            type: 'collapse',
            icon: icons.IconFriends,
            systemSubModule: 'NONE',
            children: [
                {
                    id: 'my-customers',
                    title: 'Buyers',
                    type: 'item',
                    url: 'app/farmer/customers/my-customers',
                    breadcrumbs: false,
                    feature: 'NONE'
                }
            ]
        }
    ]
};

export default farmerMenuItems;
