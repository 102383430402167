// assets
import {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconFriends,
    IconLockAccess,
    IconBriefcase,
    IconAdjustments
} from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconLockAccess,
    IconFriends,
    IconAdjustments
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const adminMenuItems = {
    id: 'user-management',
    title: 'User Management',
    type: 'group',
    systemModule: 'NONE',
    children: [
        {
            id: 'user-management',
            title: 'User Management',
            type: 'collapse',
            icon: icons.IconFriends,
            systemSubModule: 'NONE',
            children: [
                {
                    id: 'admin-management',
                    title: 'Admin Management',
                    type: 'item',
                    url: '/app/users/admin-management',
                    breadcrumbs: false,
                    feature: 'NONE'
                },
                {
                    id: 'staff-management',
                    title: 'Staff Management',
                    type: 'item',
                    url: '/app/users/staff-management',
                    breadcrumbs: false,
                    feature: 'NONE'
                },
                {
                    id: 'farmer-management',
                    title: 'Farmer Management',
                    type: 'item',
                    url: '/app/users/farmer-management',
                    breadcrumbs: false,
                    feature: 'NONE'
                }
            ]
        },
        {
            id: 'user-permissions',
            title: 'User Permissions',
            type: 'collapse',
            icon: icons.IconLockAccess,
            systemSubModule: 'NONE',
            children: [
                {
                    id: 'permissions-combinations',
                    title: 'Access Combinations',
                    type: 'item',
                    url: '/app/users/user-permissions',
                    breadcrumbs: false,
                    feature: 'NONE'
                },
                {
                    id: 'permissions-assignment',
                    title: 'Access Assignments',
                    type: 'item',
                    url: '/app/users/user-permissions/assignment',
                    breadcrumbs: false,
                    feature: 'NONE'
                }
            ]
        },
        {
            id: 'organisation-settings',
            title: 'Organisarion Settings',
            type: 'collapse',
            icon: icons.IconAdjustments,
            systemSubModule: 'NONE',
            children: [
                {
                    id: 'dfa-settings',
                    title: 'DFA settings',
                    type: 'item',
                    url: '/app/users/dfa-settings',
                    breadcrumbs: false,
                    feature: 'NONE'
                }
            ]
        },
        {
            id: 'organistion-partner-management',
            title: 'Organisation/Partner Management',
            type: 'collapse',
            icon: icons.IconFriends,
            systemSubModule: 'NONE',
            children: [
                {
                    id: 'organistion-partner',
                    title: 'Organisation/Partner',
                    type: 'item',
                    url: '/app/users/organistion-partner-management/organistion-partner',
                    breadcrumbs: false,
                    feature: 'NONE'
                }
            ]
        }
        // {
        //     id: 'applications-management',
        //     title: 'Application Management',
        //     type: 'collapse',
        //     icon: IconBriefcase,
        //     systemSubModule: 'NONE',
        //     children: [
        //         {
        //             id: 'applications-management2',
        //             title: 'Manage Applications',
        //             type: 'item',
        //             url: '/app/users/applications-management',
        //             breadcrumbs: false,
        //             feature: 'NONE'
        //         }
        //     ]
        // }
    ]
};

export default adminMenuItems;
