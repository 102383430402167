import secureLocalStorage from 'react-secure-storage';
export function currentUserUtils() {
    return JSON.parse(secureLocalStorage.getItem('currentUser'));
}

export function userPermissionUtils() {
    try {
        console.log('ssfsf', JSON.parse(secureLocalStorage.getItem('roles')));
        if (JSON.parse(secureLocalStorage.getItem('roles')) === null) {
            return [];
        } else {
            return JSON.parse(secureLocalStorage.getItem('roles'));
        }
    } catch (error) {
        return [];
    }
}
