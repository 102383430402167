import { currentUserUtils, userPermissionUtils } from 'utils/currentUserUtils';
import { isValidAccessToken, routeSelector } from 'utils/tokenVerifier';
import { useNavigate, useLocation } from 'react-router-dom';

export function AuthGuard({ children, roleType, permissions }) {
    const navigate = useNavigate();
    const location = useLocation();

    try {
        const { userType } = currentUserUtils();
        const hasRequiredPermissions = () => {
            if (permissions?.feature === 'NONE') {
                return true;
            }
            const perms =
                userPermissionUtils() !== null
                    ? userPermissionUtils().find((perm) => perm?.feature === permissions?.feature)
                    : navigate('app/login', { state: { path: pathname }, replace: true });
            return perms !== undefined ? true : false;
        };
        return isValidAccessToken() && hasRequiredPermissions() && userType === roleType ? children : routeSelector();
    } catch (e) {
        navigate('app/login', { state: { path: pathname }, replace: true });

        console.log('ddd', e);
        routeSelector();
    }
}
