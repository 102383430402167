/* eslint-disable prettier/prettier */
// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconFriends, IconLockAccess, IconBriefcase, IconTractor, IconSeeding } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconLockAccess,
    IconFriends,
    IconSeeding
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const staffApplicationMenuItems = {
    id: 'application-management',
    title: 'Membership Management',
    type: 'group',
    systemModule: 'NONE',
    children: [
        {
            id: 'application-management',
            title: 'Application Management',
            type: 'collapse',
            icon: IconBriefcase,
            systemSubModule: 'NONE',
            children: [
                {
                    id: 'national-application',
                    title: 'National Applications',
                    type: 'item',
                    url: '/app/users/national-application',
                    breadcrumbs: false,
                    feature: 'NONE'
                },
                {
                    id: 'applications-management2',
                    title: 'Manage DFA Applications',
                    type: 'item',
                    url: '/app/users/applications-management',
                    breadcrumbs: false,
                    feature: 'NONE'
                }
            ]
        },
        {
            id: 'profiling',
            title: 'Member Profiling',
            type: 'collapse',
            icon: IconTractor,
            systemSubModule: 'RECORDS',
            children: [
                {
                    id: 'dfa-records',
                    title: 'DFA Details',
                    type: 'item',
                    url: '/app/users/dfa-records',
                    breadcrumbs: false,
                    feature: 'ASSOCIATION_RECORDS'
                },
                {
                    id: 'farmer-records',
                    title: 'Individual Farmer Details',
                    type: 'item',
                    url: '/app/users/farmer-records',
                    breadcrumbs: false,
                    feature: 'FARMERS_RECORDS'
                },
                {
                    id: 'farmer-groups',
                    title: 'Farmer Group Details',
                    type: 'item',
                    url: '/app/users/farmer-groups',
                    breadcrumbs: false,
                    feature: 'FARMER_GROUP_RECORDS'
                },
                {
                    id: 'other-agriculture-companies',
                    title: 'Other Agriculture Company Details',
                    type: 'item',
                    url: '/app/users/other-agriculture-companies',
                    breadcrumbs: false,
                    feature: 'FARMER_GROUP_RECORDS'
                },
                {
                    id: 'farm-management',
                    title: 'Farm Management',
                    type: 'item',
                    url: '/app/users/farm-management',
                    breadcrumbs: false,
                    feature: 'FARMING_MANAGEMENT'
                }
                // {
                //     id: 'exit-management',
                //     title: 'Exit Management',
                //     type: 'item',
                //     url: '/app/users/exit-management',
                //     breadcrumbs: false,
                //     feature: 'EXIT_MANAGEMENT'
                // }
            ]
        }
    ]
};

export default staffApplicationMenuItems;
