// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconFriends, IconLockAccess, IconBriefcase } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconLockAccess,
    IconFriends
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const associaionApplicationsMenuItems = {
    id: 'application-management',
    title: 'Membership Management',
    type: 'group',
    systemModule: 'NONE',
    children: [
        {
            id: 'application-management',
            title: 'Application Management',
            type: 'collapse',
            icon: IconBriefcase,
            systemSubModule: 'NONE',
            children: [
                {
                    id: 'dfa-applications',
                    title: 'Manage DFA Applications',
                    type: 'item',
                    url: '/app/association/dfa-applications',
                    breadcrumbs: false,
                    feature: 'NONE'
                }
            ]
        }
    ]
};

export default associaionApplicationsMenuItems;
