// assets
import { IconTypography, IconPalette, IconShadow, IconWindmill, IconFriends, IconLockAccess, IconFileReport } from '@tabler/icons';

// constant
const icons = {
    IconTypography,
    IconPalette,
    IconShadow,
    IconWindmill,
    IconLockAccess,
    IconFriends,
    IconFileReport
};

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const adminContractMgtMenuItems = {
    id: 'contract-management',
    title: 'Contract Management',
    type: 'group',
    systemModule: 'CONTRACT_MANAGEMENT',
    children: [
        {
            id: 'contract-management',
            title: 'Contract Management',
            type: 'collapse',
            icon: icons.IconFileReport,
            systemSubModule: 'CONTRACT',
            children: [
                {
                    id: 'contracts-configuration',
                    title: 'Contracts',
                    type: 'item',
                    url: '/app/admin/contracts/contracts-configuration',
                    breadcrumbs: false,
                    feature: 'CONTRACT'
                },
                {
                    id: 'contracts-tracking',
                    title: 'Contract Tracking',
                    type: 'item',
                    url: '/app/admin/contracts/contracts-tracking',
                    breadcrumbs: false,
                    feature: 'CONTRACT'
                }
            ]
        }
    ]
};

export default adminContractMgtMenuItems;
