// assets
import { IconTractor } from '@tabler/icons';

// constant

// ==============================|| UTILITIES MENU ITEMS ||============================== //

const associationFarmerProfilingMenuItems = {
    id: 'association-profiling',
    title: 'Profiling',
    type: 'group',
    systemModule: 'NONE',
    children: [
        {
            id: 'association-profiling',
            title: 'Profiling',
            type: 'collapse',
            icon: IconTractor,
            systemSubModule: 'NONE',
            children: [
                {
                    id: 'association-farmer-records',
                    title: 'Farmer Records',
                    type: 'item',
                    url: '/app/association/association-farmer-records',
                    breadcrumbs: false,
                    feature: 'NONE'
                },
                {
                    id: 'association-farmer-groups',
                    title: 'Farmer Group Records',
                    type: 'item',
                    url: '/app/association/association-farmer-groups',
                    breadcrumbs: false,
                    feature: 'NONE'
                }
                // {
                //     id: 'farm-management',
                //     title: 'Farm Management',
                //     type: 'item',
                //     url: '/app/association/association-farm-management',
                //     breadcrumbs: false,
                //     feature: 'NONE'
                // }
            ]
        }
    ]
};

export default associationFarmerProfilingMenuItems;
