import { API_BASE_URL, ACCESS_TOKEN } from '../constants';
import secureLocalStorage from 'react-secure-storage';

const request = async (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json'
    });

    if (secureLocalStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + secureLocalStorage.getItem(ACCESS_TOKEN));
    }

    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);
    const response = await fetch(options.url, options);
    const json = await response.json();
    if (!response.ok) {
        return Promise.reject(json);
    }
    return json;
};

export function getFarmerDashboard() {
    return request({
        url: API_BASE_URL + 'dashboard/farmer',
        method: 'GET'
    });
}

export function getStaffDashboard() {
    return request({
        url: API_BASE_URL + 'dashboard/staff',
        method: 'GET'
    });
}
