import { API_BASE_URL, ACCESS_TOKEN, APPLICATION_SERVICE_API_URL } from '../constants';
import secureLocalStorage from 'react-secure-storage';

const request = async (options) => {
    const headers = new Headers({
        'Content-Type': 'application/json'
    });

    if (secureLocalStorage.getItem(ACCESS_TOKEN)) {
        headers.append('Authorization', 'Bearer ' + secureLocalStorage.getItem(ACCESS_TOKEN));
    }

    const defaults = { headers: headers };
    options = Object.assign({}, defaults, options);
    const response = await fetch(options.url, options);
    const json = await response.json();
    if (!response.ok) {
        return Promise.reject(json);
    }
    return json;
};

export function loginRequest(params) {
    return request({
        url: API_BASE_URL + 'authorize/sign-in',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function createUser(params) {
    return request({
        url: API_BASE_URL + 'authorize/sign-up',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function changePassword(params) {
    return request({
        url: API_BASE_URL + 'account-management/change-password',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function getMyDetails() {
    return request({
        url: API_BASE_URL + 'user/get-my-details',
        method: 'GET'
    });
}
export function updateUserDetails(params) {
    return request({
        url: API_BASE_URL + 'account-management/account-person-update',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function getListOfAllUsers(params) {
    return request({
        url: API_BASE_URL + 'account-management/get-users',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function changeUserAccountStatus(params) {
    return request({
        url: API_BASE_URL + 'account-management/account-activate',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function deleteUserAccount(params) {
    return request({
        url: API_BASE_URL + 'account-management/delete-user',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function getLocations() {
    return request({
        url: API_BASE_URL + 'authorize/get-locations',
        method: 'GET'
    });
}
// export function getLocations() {
//     return request({
//         url: APPLICATION_SERVICE_API_URL + 'location/get-locations',
//         method: 'GET'
//     });
// }

export function showRegistrationInterest(params) {
    return request({
        url: API_BASE_URL + 'authorize/show-interest',
        method: 'POST',
        body: JSON.stringify(params)
    });
}

export function getApplications(params) {
    return request({
        url: API_BASE_URL + 'authorize/get-applications',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function onBoardFarmer(params) {
    return request({
        url: API_BASE_URL + 'farmer-profiling/onboard-farmer',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function approveApplication(params) {
    return request({
        url: API_BASE_URL + 'farmer-profiling/approve-application',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function declineApplication(params) {
    return request({
        url: API_BASE_URL + 'farmer-profiling/decline-application',
        method: 'POST',
        body: JSON.stringify(params)
    });
}

export function getFarmProfile(params) {
    return request({
        url: API_BASE_URL + 'farmer-profiling/get-farm-profile',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function createEditFarmProfile(params) {
    return request({
        url: API_BASE_URL + 'farmer-profiling/create-edit-farm-profile',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function deleteFarmProfile(params) {
    return request({
        url: API_BASE_URL + 'farmer-profiling/delete-farm-profile',
        method: 'DELETE',
        body: JSON.stringify(params)
    });
}
export function archiveFarmerProfile(params) {
    return request({
        url: API_BASE_URL + 'farmer-profiling/archive-farmer-profile',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function deleteFarmerProfile(params) {
    return request({
        url: API_BASE_URL + 'farmer-profiling/delete-farmer-profile',
        method: 'DELETE',
        body: JSON.stringify(params)
    });
}

export function getInputTypes() {
    return request({
        url: API_BASE_URL + 'value-chain-profiling/get-input-type',
        method: 'GET'
    });
}
export function getFarmInput(params) {
    return request({
        url: API_BASE_URL + 'value-chain-profiling/get-input',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function createUpdateInput(params) {
    return request({
        url: API_BASE_URL + 'value-chain-profiling/create-update-input',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function deleteFarmInput(params) {
    return request({
        url: API_BASE_URL + 'value-chain-profiling/delete-input',
        method: 'DELETE',
        body: JSON.stringify(params)
    });
}

/**
 * Production To Harvest
 */

export function createUpdateProductionToHarvestPlan(params) {
    return request({
        url: API_BASE_URL + 'farm-to-fork/create-update-production-to-harvest-plan',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function getProductionToHarvestPlans(params) {
    return request({
        url: API_BASE_URL + 'farm-to-fork/get-production-to-harvest-plans',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function getProductionToHarvestPlansIndividual(params) {
    return request({
        url: API_BASE_URL + 'farm-to-fork/get-production-to-harvest-plans-individual',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function deleteProductionToHarvestPlans(params) {
    return request({
        url: API_BASE_URL + 'farm-to-fork/delete-production-to-harvest-plans',
        method: 'DELETE',
        body: JSON.stringify(params)
    });
}
// Get Break down
export function getProductionToHarvestBreakDowns(params) {
    return request({
        url: API_BASE_URL + 'farm-to-fork/get-production-to-harvest-break-down',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function createUpdateProductionToHarvestBreakDown(params) {
    return request({
        url: API_BASE_URL + 'farm-to-fork/create-update-production-to-harvest-break-down',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function deleteProductionToHarvestBreakDown(params) {
    return request({
        url: API_BASE_URL + 'farm-to-fork/delete-production-to-harvest-break-down',
        method: 'DELETE',
        body: JSON.stringify(params)
    });
}
export function getQuantityUnit() {
    return request({
        url: API_BASE_URL + 'farm-to-fork/get-quantity-unit',
        method: 'GET'
    });
}
export function getAgricultureType() {
    return request({
        url: API_BASE_URL + 'farm-to-fork/get-agriculture-type',
        method: 'GET'
    });
}
export function getMeasurementScope() {
    return request({
        url: API_BASE_URL + 'farm-to-fork/get-measurement-scope',
        method: 'GET'
    });
}
export function getStage() {
    return request({
        url: API_BASE_URL + 'farm-to-fork/get-stage',
        method: 'GET'
    });
}
// Production Logs
export function getProductionLogs(params) {
    return request({
        url: API_BASE_URL + 'farm-to-fork/get-production-logs',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function getProductionLogsByHarvestPlan(params) {
    return request({
        url: API_BASE_URL + 'farm-to-fork/get-production-logs-by-harvest',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function createUpdateProductionLog(params) {
    return request({
        url: API_BASE_URL + 'farm-to-fork/create-update-production-log',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function deleteProductionLog(params) {
    return request({
        url: API_BASE_URL + 'farm-to-fork/delete-production-log',
        method: 'DELETE',
        body: JSON.stringify(params)
    });
}

export function getOfferToSaleCatalogue() {
    return request({
        url: API_BASE_URL + 'public/get-offer-to-sale-catalogue',
        method: 'GET'
    });
}

// Publish Offer to Sale.
export function createUpdateOfferToSale(params) {
    return request({
        url: API_BASE_URL + 'farm-to-fork/create-update-offer-to-sale',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
export function deleteOfferToSale(params) {
    return request({
        url: API_BASE_URL + 'farm-to-fork/delete-offer-to-sale',
        method: 'DELETE',
        body: JSON.stringify(params)
    });
}

// Production Status
export function getProductionToHarvestPlansStatus(params) {
    return request({
        url: API_BASE_URL + 'farm-to-fork/get-production-plan-status',
        method: 'POST',
        body: JSON.stringify(params)
    });
}

// Submit Request to Buy
export function submitRequestToBuy(params) {
    return request({
        url: API_BASE_URL + 'authorize/submit-request-to-buy',
        method: 'POST',
        body: JSON.stringify(params)
    });
}

export function getRequestsToBuy(params) {
    return request({
        url: API_BASE_URL + 'farm-to-fork/get-requests-to-buy',
        method: 'POST',
        body: JSON.stringify(params)
    });
}

// Production Analysis
export function getGeneralProductionAnalysis(params) {
    return request({
        url: API_BASE_URL + 'farm-to-fork/get-general-production-analysis',
        method: 'POST',
        body: JSON.stringify(params)
    });
}
